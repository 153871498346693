import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    }
};


export default function Detail(props) {
    //localStorage.clear(); 
    const { id, subcategory } = useParams();
    const [dtl, setDtl] = useState([]);
    const [images, setImages] = useState([]);
    const [color, setColor] = useState([]);
    const [size, setSize] = useState([]);
    const [loading, setLoading] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [qty, setQty] = useState(1);
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectcolor, setSelectcolor] = useState();
    const [selectsize, setSelectsize] = useState();

    
    useEffect(() => {
        productdetail(id);  
    }, [cart]);

    const productdetail = () => {
        window.scrollTo(0, 0);
        setLoading('Loading...');
        axios
            .get(`http://onkar.world/react_apis/detail.php?id=${id}`)
            .then(function (response) {
                
                setImages(response.data.res.images);
                setDtl(response.data.res.detail);
                setColor(response.data.res.color);
                setSize(response.data.res.size);
                setLoading('');
                
            })
            .catch(function (error) { });
    };

    const plusqty = () =>{
        setQty(qty+1);
    }
    const minqty = () =>{
        if(qty>1)
        {
            setQty(qty-1);
        }
        
    }

    const selectColor = (e)=>{
        setSelectcolor(e.target.value);
    }
    const selectSize = (e)=>{
        setSelectsize(e.target.value);
    }
    return (
        <>
    
            <div className="container-fluid bg-secondary mb-5">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "100px" }}>
                    <h1 className="font-weight-semi-bold text-uppercase mb-3">Shop Detail</h1>
                    <div className="d-inline-flex">
                        <p className="m-0"><a to="">Home</a></p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">Shop Detail</p>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" reverseOrder={false} />
            <center><h3>{loading}</h3></center>
            <div className="container-fluid py-2">
                <div className="row px-xl-5">
                    
                    <div className="col-lg-5 pb-5">
                        <div id="product-carousel" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner border">
                                {images && images.map((object, i) => {
                                    return (

                                        <div key={i} className={`carousel-item ${i == 0 ? "active" : ""}`}>
                                            <img className="w-100 h-50" src={`https://monteirocart.com/img/${object.images}`} alt="Image" />
                                        </div>
                                    );

                                })}

                            </div>
                            <a className="carousel-control-prev" href="#product-carousel" data-slide="prev">
                                <i className="fa fa-2x fa-angle-left text-dark"></i>
                            </a>
                            <a className="carousel-control-next" href="#product-carousel" data-slide="next">
                                <i className="fa fa-2x fa-angle-right text-dark"></i>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-7 pb-5">
                        <h3 className="font-weight-semi-bold">{dtl.name}</h3>
                        <div className="d-flex mb-3">
                            <div className="text-primary mr-2">
                                <small className="fas fa-star"></small>
                                <small className="fas fa-star"></small>
                                <small className="fas fa-star"></small>
                                <small className="fas fa-star-half-alt"></small>
                                <small className="far fa-star"></small>
                            </div>
                            <small className="pt-1">(50 Reviews)</small>
                        </div>
                        <h3 className="font-weight-semi-bold mb-4">${dtl.price}</h3>
                        <p className="mb-4">{dtl.details}</p>
                        <div className="d-flex mb-3">
                            <p className="text-dark font-weight-medium mb-0 mr-3">Sizes:</p>
                            <form>
                                
                                {size && size.map((object, i) => {
                                    i=i+1;
                                    return (

                                        <div key={object["id"+i]} className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="custom-control-input" id={`size-${object["id"+i]}`} onChange={selectSize} name="size" value={object["k"+i]}/>
                                            <label className="custom-control-label" htmlFor={`size-${object["id"+i]}`}>{object["k"+i]}</label>
                                        </div>
                                    );

                                })}


                            </form>
                        </div>
                        <div className="d-flex mb-4">
                            <p className="text-dark font-weight-medium mb-0 mr-3">Colors:</p>
                            <form>
                            {color && color.map((object, i) => {
                                    i=i+1;
                                    return (

                                       
                                        <div key={i} className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" className="custom-control-input" id={`color-${object["id"+i]}`} name="color" value={`${object["k"+i]}`} onChange={selectColor}/>
                                        <label className="custom-control-label" htmlFor={`color-${object["id"+i]}`}>{`${object["k"+i]}`}</label>
                                    </div>
                                    );

                                })}
                                
                                
                                
                            </form>
                        </div>

                        <div className="d-flex align-items-center mb-4 pt-2">
                            <div className="input-group quantity mr-3" style={{ width: "130px" }}>
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-minus" onClick={minqty}>
                                        <i className="fa fa-minus"></i>
                                    </button>
                                </div>
                                <input type="text" className="form-control bg-secondary text-center" value={qty} />
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-plus" onClick={plusqty}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary px-3" key={dtl.id} onClick={() => props.addItem(dtl,qty,selectsize,selectcolor)}><i className="fa fa-shopping-cart mr-1"></i> Add To Cart</button>
                        </div>
                        <div className="d-flex pt-2">
                            <p className="text-dark font-weight-medium mb-0 mr-2">Share on:</p>
                            <div className="d-inline-flex">
                                <a className="text-dark px-2" to="">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a className="text-dark px-2" to="">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a className="text-dark px-2" to="">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                                <a className="text-dark px-2" to="">
                                    <i className="fab fa-pinterest"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row px-xl-5">
                    <div className="col">
                        <div className="nav nav-tabs justify-content-center border-secondary mb-4">
                            <a className="nav-item nav-link active" data-toggle="tab" href="#tab-pane-1">Description</a>
                            <a className="nav-item nav-link" data-toggle="tab" href="#tab-pane-3">Reviews (0)</a>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tab-pane-1">
                                <h4 className="mb-3">Product Description</h4>
                                <p>{dtl.description}</p>
                            </div>

                            <div className="tab-pane fade" id="tab-pane-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="mb-4">1 review for "Colorful Stylish Shirt"</h4>
                                        <div className="media mb-4">
                                            <img src="img/user.jpg" alt="Image" className="img-fluid mr-3 mt-1" style={{ width: "45px" }} />
                                            <div className="media-body">
                                                <h6>John Doe<small> - <i>01 Jan 2045</i></small></h6>
                                                <div className="text-primary mb-2">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star-half-alt"></i>
                                                    <i className="far fa-star"></i>
                                                </div>
                                                <p>Diam amet duo labore stet elitr ea clita ipsum, tempor labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="mb-4">Leave a review</h4>
                                        <small>Your email address will not be published. Required fields are marked *</small>
                                        <div className="d-flex my-3">
                                            <p className="mb-0 mr-2">Your Rating * :</p>
                                            <div className="text-primary">
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                            </div>
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="message">Your Review *</label>
                                                <textarea id="message" cols="30" rows="5" className="form-control"></textarea>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Your Name *</label>
                                                <input type="text" className="form-control" id="name" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Your Email *</label>
                                                <input type="email" className="form-control" id="email" />
                                            </div>
                                            <div className="form-group mb-0">
                                                <input type="submit" value="Leave Your Review" className="btn btn-primary px-3" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5">
                <div className="text-center mb-4">
                    <h2 className="section-title px-5"><span className="px-2">You May Also Like</span></h2>
                </div>
                <div className="row px-xl-5">
                    <div className="col">
                        <div>
                            <Carousel
                                swipeable={false}
                                draggable={true}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={props.deviceType !== "mobile" ? true : false}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={1500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                deviceType={props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-10-px"
                            >


                                <div className="card product-item border-0">
                                <Link key="9" onClick={() => productdetail("9")} to="/detail/9" className="btn btn-sm text-dark p-0"><div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                        <img className="img-fluid w-100" src="../../img/product-1.jpg" alt="" />
                                    </div>
                                    <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                        <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                                        <div className="d-flex justify-content-center">
                                            <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$123.00</del></h6>
                                        </div>
                                    </div></Link>
                                    <div className="card-footer d-flex justify-content-between bg-light border">
                                        <Link to="/detail/9" className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1"></i>View Detail</Link>
                                        <Link to="" className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</Link>
                                    </div>
                                </div>
                                <div className="card product-item border-0">
                                <Link to="/detail/18" onClick={() => productdetail("18")} className="btn btn-sm text-dark p-0"><div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                        <img className="img-fluid w-100" src="../../img/product-2.jpg" alt="" />
                                    </div>
                                    <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                        <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                                        <div className="d-flex justify-content-center">
                                            <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$124.00</del></h6>
                                        </div>
                                    </div></Link>
                                    <div className="card-footer d-flex justify-content-between bg-light border">
                                        <Link to={`/detail/18`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1"></i>View Detail</Link>
                                        <Link to="" className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</Link>
                                    </div>
                                </div>
                                <div className="card product-item border-0">
                                <Link key="19" onClick={() => productdetail("19")} to="/detail/19" className="btn btn-sm text-dark p-0"><div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                        <img className="img-fluid w-100" src="../../img/product-3.jpg" alt="" />
                                    </div>
                                    <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                        <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                                        <div className="d-flex justify-content-center">
                                            <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$125.00</del></h6>
                                        </div>
                                    </div></Link>
                                    <div className="card-footer d-flex justify-content-between bg-light border">
                                        <Link to="/detail/19" className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1"></i>View Detail</Link>
                                        <Link to="" className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</Link>
                                    </div>
                                </div>
                                <div className="card product-item border-0">
                                <Link key="20" to="/detail/20" onClick={() => productdetail("20")} className="btn btn-sm text-dark p-0"><div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                        <img className="img-fluid w-100" src="../../img/product-4.jpg" alt="" />
                                    </div>
                                    <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                        <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                                        <div className="d-flex justify-content-center">
                                            <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$126.00</del></h6>
                                        </div>
                                    </div></Link>
                                    <div className="card-footer d-flex justify-content-between bg-light border">
                                        <Link to="/detail/20" className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1"></i>View Detail</Link>
                                        <Link to="" className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</Link>
                                    </div>
                                </div>
                                <div className="card product-item border-0">
                                <Link key="22" to="/detail/22" onClick={() => productdetail("22")} className="btn btn-sm text-dark p-0"><div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                        <img className="img-fluid w-100" src="../../img/product-5.jpg" alt="" />
                                    </div>
                                    <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                        <h6 className="text-truncate mb-3">Colorful Stylish Shirt</h6>
                                        <div className="d-flex justify-content-center">
                                            <h6>$123.00</h6><h6 className="text-muted ml-2"><del>$129.00</del></h6>
                                        </div>
                                    </div></Link>
                                    <div className="card-footer d-flex justify-content-between bg-light border">
                                        <Link to="/detail/22" className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1"></i>View Detail</Link>
                                        <Link to="" className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</Link>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

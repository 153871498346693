import React, {useState, useEffect} from 'react'
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Products(props) {

    const {id,search, subcategory} = useParams();
    const [products, setProducts] = useState([]);
    const [progress, setProgress] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [itemsize, setItemsize] = useState(9);
    let url = "";
    
    useEffect(() => {
        productlist();
      }, [props]);
      
      if(id!='' && id!== undefined)
        {
            url=`http://onkar.world/react_apis/products.php?id=${id}&itemsize=${itemsize}`;    
        }
        else if(search!='' && search!== undefined)
            {;
                url=`http://onkar.world/react_apis/products.php?search=${search}&itemsize=${itemsize}`;
            }
        else{        
            url=`http://onkar.world/react_apis/products.php?itemsize=${itemsize}`;
        }
      const productlist = () => {
        
          setProgress(progress + 0);
        axios
          .get(url)
          .then(function (response) {
              setProducts(response.data.res);
              setProgress(progress + 100);
              setItemsize(itemsize + 9);
              if(response.data.res.length == response.data.total)
              {
                  setHasMore(false);
              }
              else{                
                  setHasMore(true);
              }
              
              
          })
          .catch(function (error) {});
      };

  return (
    <>
    <ToastContainer position="top-right" reverseOrder={false} />
          <InfiniteScroll
          dataLength={products.length}
          next={productlist}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        >
        
                <div className="row pb-3">
                
                    {products.length > 0 ? products.map((object, i) => {
                
                return(
                    <div key={i} className="col-lg-4 col-md-6 col-sm-12 pb-1">
                        <div className="card product-item border-0 mb-4">
                        <Link to={`/detail/${object.id}`} className="btn btn-sm text-dark p-0"><div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                <img className="img-fluid w-100" src={`https://monteirocart.com/img/${object.images}`} alt="" style={{height:"300px"}}/>
                            </div>
                            <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                <h6 className="text-truncate mb-3">{object.name}</h6>
                                <div className="d-flex justify-content-center">
                                    <h6>${object.price}</h6>
                                </div>
                            </div></Link>
                            <div className="card-footer d-flex justify-content-between bg-light border">
                                <Link to={`/detail/${object.id}`} style={{ margin:"auto" }} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-primary mr-1"></i>View Detail</Link>
                                {/* <Link to="#" key={object.id} onClick={() => props.addItem(object,1)} className="btn btn-sm text-dark p-0"><i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</Link> */}
                            </div>
                        </div>
                    </div>
                
                  );
                
                
                }) : (
                    <center style={{margin:"auto"}}><h4>No data found!</h4></center>
                  )} 
            
                </div>
                </InfiniteScroll> 
    </>
  )
}

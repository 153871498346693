import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default function Cart(props) {
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState();
const [subqty, setSubqty] = useState();
  
  useEffect(() => {
    cal_subtotal();
  }, [props.carts]);

  const cal_subtotal = () =>{
    let totalamn = 0;
    let totalqtys = 0;
    {props.carts.map((items) => (
        totalamn+=items.quantity*items.price
    ))}
    {props.carts.map((ite) => (
        totalqtys+=ite.quantity
    ))}
    setSubtotal(totalamn);
    setSubqty(totalqtys);
  }

  return (
    <>
      <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "100px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">Cart</h1>
          <div className="d-inline-flex">
            <p className="m-0">
              <a to="">Home</a>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">Cart</p>
          </div>
        </div>
      </div>
      <div className="container-fluid py-2">
        <div className="row px-xl-5">
           <ToastContainer position="top-right" reverseOrder={false} />
        {props.carts.length === 0 ? (
          <p>Your cart is empty</p>
        ) : (
          <div className="cart-items">
            
          </div>
        )}
       

          <div id="page">
            <table id="cart" style={{textAlign:"center",border:"1px solid #ccc"}}>
              <thead style={{backgroundColor:"#D19C97",fontSize:"14px"}}>
                <tr>
                  {/* <th className="first">Photo</th> */}
                  {/* <th className="third">Image</th> */}
                  <th className="third">Product</th>
                  <th className="third">Size</th>
                  <th className="third">Color</th>
                  <th className="second">Qty</th>
                  <th className="fourth">Sub Total</th>
                  <th className="fifth">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
              
            {props.carts.map((item,index) => (
                <tr key={item.id} style={{fontSize:"12px"}}>
                   {/* <td>
                   <img className="thumb" src={`https://monteirocart.com/img/${item.images}`} alt="" style={{height:"60px"}}/>
                  </td>  */}
                  <td>{item.name}</td>
                  <td>{item.size}</td>
                  <td>{item.color}</td>
                  <td>
                  <div className="item-controls" style={{display:"flex"}}>
                  <button className="btn btn-primary mr-3" onClick={() => item.quantity > 1 ? props.decreaseqty(item,index) : ""}>-</button>
                  <span className="pt-2">{item.quantity}</span>
                  <button key={item.id} className="btn btn-primary ml-3" onClick={() => props.addItem(item,1,1)}>+</button>
                </div>
                  </td>
                  
                  <td>${item.price * item.quantity}</td>
                  <td>
                    <span style={{ cursor:"pointer" }}onClick={() => props.removeItm(item,index)} className="remove">
                      <img src="https://i.imgur.com/h1ldGRr.png" alt="X" />
                    </span>
                  </td>
                </tr>
                  
            ))}
               

                <tr><td style={{height:"40px"}}></td></tr>
                <tr className="totalprice">
                  <td className="light" colSpan="2">&nbsp;</td>
                  <td colSpan="2"><h5>Total Qty:</h5></td>
                  <td colSpan="1">
                    <span className="thick">{subqty}</span>
                  </td>
                </tr>

                <tr className="totalprice">
                  <td className="light" colSpan="2">&nbsp;</td>
                  <td colSpan="2"><h5>Total Amount:</h5></td>
                  <td colSpan="1">
                    <span className="thick">${subtotal}</span>
                  </td>
                </tr>

                <tr className="checkoutrow">
                  <td colSpan="5" className="checkout">
                    <Link to="/shop"><button className="btn btn-success m-2">Shop More!</button></Link>
                    {props.carts.length === 0 ? "" : (
                    <Link to={ (sessionStorage.getItem("email")!='' && sessionStorage.getItem("email")!== null)  ? "/checkout" : "/login"}><button className="btn btn-primary m-2">Checkout Now!</button></Link>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react'
import logo from "./logo.svg";
import "./App.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Faq from "./pages/Faq";
import Register from "./user/Register";
import Login from "./user/Login";
import Logout from "./user/Logout";
import Dashboard from "./user/Dashboard";
import Myorders from "./user/Myorders";
import ChangePassword from "./user/ChangePassword";
import Home from "./components/Home";
import Shop from "./components/Shop";
import Detail from "./components/Detail";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import Thankyou from "./components/Thankyou";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

function App(props) {

const [cart, setCart] = useState([]);
const [qty, setQty] = useState(1);
const [subtotal, setSubtotal] = useState();
const [subqty, setSubqty] = useState();
const [mesg, setMesg] = useState("");
const [logout, setLogout] = useState(false);

// Add time to cart
const addItem = (item,qty,size,color,msg) => {
  
  if(size  === undefined || color === undefined){
    toast.error("Please select size and color !!!");
  }
  else{
  const existingItem = cart.find((cartItem) => cartItem.id === item.id);
  if (existingItem) {
    
    setCart(
      cart.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity + qty }
          : cartItem
      )
    );
  } else {
    setCart([...cart, { ...item, quantity: qty, color: color, size: size }]);
  }
  if(msg=='' || msg == undefined)
  {
    toast.success("Item added to cart !");
  }
}
};
// Add item to cart

// remove item from cart
const removeItm = (item, idd) => {
  let items = JSON.parse(localStorage.getItem("item"));
  items = cart.filter((item,o) => o !== idd);    
  setCart(items);
};
// remove item from cart


// decrease cart qty
const decreaseqty = (item,index) => {
  const existingItem = cart.find((cartItem) => cartItem.id === item.id);
  if (existingItem.quantity === 1) {
    setCart(cart.filter((cartItem) => cartItem.id !== item.id));
    
  } else {
    setCart(
      cart.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity - 1 }
          : cartItem
      )
    );
    
  }
};
// decrease cart qty

  // Different types of toasts

  //toast.error("This didn't\n work.")

  // toast('Good Job!', {
  //     icon: '👏',
  //   });

  // toast.promise(
  //     saveSettings(settings),
  //      {
  //        loading: 'Saving...',
  //        success: <b>Settings saved!</b>,
  //        error: <b>Could not save.</b>,
  //      }
  //    );
  // different types of toasts

// login user

const loginUser = (e) => {
  e.preventDefault();
  
    const data = {
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
    };

    axios
      .post("http://onkar.world/react_apis/login.php", JSON.stringify(data))
      .then(function (response) {
        if(response.data.status ==1){
        sessionStorage.setItem("email", response.data.email);
        sessionStorage.setItem("profileid", response.data.profileid);
        sessionStorage.setItem("name", response.data.name);
        setMesg({
          msg: response.data.msg,
          typ: response.data.status === "1" ? "success" : "danger",
        });
        setLogout(false);
      }
      else{
        setMesg({
          msg: response.data.msg,
          typ: "danger",
        });
      }
      })
      .catch(function (error) {
        
        setMesg({
          msg: error,
          typ: "danger",
        });
      });
  
};

// login user

// empty cart

const removecart =()=>{
  setCart([]);
}

const change_logout_menu =()=>{
  setLogout(true);
  setMesg({
    msg: 'Logout Successfully!!!',
    typ: "success",
  });
}
// empty cart

  return (
    <>

    
      <BrowserRouter>
      
        <Header faq="FAQs" help="Help" sprt="Support" cartlength={cart.length} logout={logout}/>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/faq" element={<Faq key="FaQ" title="Faq" />} />
          <Route exact path="/help" element={<Faq ley="Help" title="Help" />} />
          <Route exact path="/support" element={<Faq key="Support" title="Support" />} />
          <Route exact path="/register" element={<Register/>} />
          <Route exact path="/login" element={<Login loginUser={loginUser} mesg={mesg}/>} />
          <Route exact path="/logout" element={<Logout change_logout_menu={change_logout_menu}/>} />
          <Route exact path="/my-orders" element={<Myorders/>} />
          <Route exact path="/change-pass" element={<ChangePassword/>} />
          <Route exact path="/dashboard" element={<Dashboard/>} />
          <Route exact path="/shop/:subcategory/:id" element={<Shop addItem={addItem}/>} />
          <Route exact path="/shop/:search" element={<Shop addItem={addItem}/>} />
          <Route exact path="/shop" element={<Shop addItem={addItem}/>} />
          <Route exact path="/detail/:id" element={<Detail key="/detail/:id" addItem={addItem}/>} />
          <Route exact path="/cart" element={<Cart carts={cart} removeItm={removeItm} decreaseqty={decreaseqty} addItem={addItem} subtotal={subtotal} subqty={subqty}/>}/>
          <Route exact path="/checkout" element={<Checkout carts={cart} subtotal={subtotal} subqty={subqty}/>} />
          <Route exact path="/thank-you" element={<Thankyou removecart={removecart}/>} />
          
        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";

export default function Header(props) {
    const navigate = useNavigate();

const searchproducts = (e)=>{
    let sp = document.getElementById("sp").value;
    navigate("/shop/"+sp);
}

  return (
    <>
    
         <div className="container-fluid">
        <div className="row bg-secondary py-2 px-xl-5">
            <div className="col-lg-6 d-none d-lg-block">
                <div className="d-inline-flex align-items-center">
                    <Link className="text-dark" to="/faq">{props.faq}</Link>
                    <span className="text-muted px-2">|</span>
                    <Link className="text-dark" to="/help">{props.help}</Link>
                    <span className="text-muted px-2">|</span>
                    <Link className="text-dark" to="/support">{props.sprt}</Link>
                </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
                <div className="d-inline-flex align-items-center">
                    <Link className="text-dark px-2" to="/">
                        <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link className="text-dark px-2" to="/">
                        <i className="fab fa-twitter"></i>
                    </Link>
                    <Link className="text-dark px-2" to="/">
                        <i className="fab fa-linkedin-in"></i>
                    </Link>
                    <Link className="text-dark px-2" to="/">
                        <i className="fab fa-instagram"></i>
                    </Link>
                    <Link className="text-dark pl-2" to="/">
                        <i className="fab fa-youtube"></i>
                    </Link>
                </div>
            </div>
        </div>
        <div className="row align-items-center py-3 px-xl-5">
            <div className="col-lg-3 d-lg-block">
                <Link to="/" className="text-decoration-none">
                    <h1 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">E</span>Shopper</h1>
                </Link>
            </div>
            <div className="col-lg-3 col-12 text-left mt-3">
                <form action="">
                    <div className="input-group">
                        <input type="text" id="sp" className="form-control" placeholder="Search for products"/>
                        <div className="input-group-append">
                            <span className="input-group-text bg-transparent text-primary" onClick={searchproducts}>
                                <i className="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-lg-3 col-5 mt-3">
            <Link to="/shop" className="btn border btn-success">
                    SHOP
                </Link>
                <Link to="/cart" className="btn border">
                    <i className="fas fa-shopping-cart text-primary"></i>
                    <span className="badge">{props.cartlength}</span>
                </Link>
                
            </div>
           
            <div className="col-lg-3 col-7 text-right">
            {(sessionStorage.getItem('email') === null || sessionStorage.getItem('email') == '' || props.logout === true) ? (
  <><Link to="/register" className="btn border">
  Register
</Link>
<Link to="/login" className="btn border">                    
  Login
</Link></>) : (
 <><Link to="/dashboard" className="btn border">
 My Account
</Link>
<Link to="/logout" className="btn border">                    
 Logout
</Link></>)}
            
            </div>
            
        </div>
    </div>
    </>
  )
}

import React,{useState,useEffect} from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import Leftbar from './Leftbar.js'

export default function ChangePassword(props) {
    const [mesg, setMesg] = useState("");

    const [inputValues, setInputValue] = useState({
        email: "",
        password: "",
      });

      //handle submit updates
      function handleChange(event) {
        const { name, value } = event.target;
        setInputValue({ ...inputValues, [name]: value });
      }
    
      const changePass = (e) => {
        e.preventDefault();
        if(document.getElementById("new_password").value!=document.getElementById("conf_password").value)
        {
            setMesg({
                msg: 'Confirm Password does not match',
                typ: "danger",
              });
        }
        else{
          const data = {
            old_password: document.getElementById("old_password").value,
            new_password: document.getElementById("new_password").value,
            profileid: sessionStorage.getItem("profileid"),
          };
      
          axios
            .post("http://onkar.world/react_apis/change_pass.php", JSON.stringify(data))
            .then(function (response) {
              console.log(response.data);
              if(response.data.status ==1){
              setMesg({
                msg: response.data.msg,
                typ: response.data.status === "1" ? "success" : "danger",
              });
            }
            else{
              setMesg({
                msg: response.data.msg,
                typ: "danger",
              });
            }
            })
            .catch(function (error) {
              
              setMesg({
                msg: error,
                typ: "danger",
              });
            });
        }
      };


      useEffect(() => {
      }, [inputValues,props]);

  return (
    <>
    <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "100px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">
          Change Password
          </h1>
          <div className="d-inline-flex">
            <p className="m-0">
              <Link to="#">Home</Link>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">Change Password</p>
          </div>
        </div>
      </div>
    
      <div className="container display-table">
        
        <div className="row">
            <Leftbar/>
            <div className="col-md-9 col-sm-11 display-table-cell v-align">
        {mesg !== "" && (
          <div className={`alert alert-${mesg.typ}`} role="alert">
            {mesg.msg}
          </div>
        )}
        
        <form
          id="registrationForm"
          action="/"
          method="POST"
          onSubmit={changePass}
        >
       
          <div className="mb-3">
            <input
              placeholder="Old password"
              type="password"
              name="old_password"
              id="old_password"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.old_password}
              required
            />
           
          </div>

          <div className="mb-3">
            <input
              placeholder="New password"
              type="password"
              name="new_password"
              id="new_password"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.new_password}
              required
            />
           
          </div>

          <div className="mb-3">
            <input
              placeholder="Confirm password"
              type="password"
              name="conf_password"
              id="conf_password"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.conf_password}
              required
            />
           
          </div>

        
          <button
            type="submit"
            id="submit-button"
            className="btn btn-primary"
            onClick={handleChange}
          >
            Submit
          </button>
        </form>
        {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} /> */}
      </div>
      </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function Checkout(props) {
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState();
  const [subqty, setSubqty] = useState();
  const [mesg, setMesg] = useState('');
  const navigate = useNavigate();

  const [inputValues, setInputValue] = useState({
    fName: "",
    lName: "",
    email: "",
  });

  const [validation, setValidation] = useState({
    fName: "",
    lName: "",
    email: "",
    subm: "",
  });

  const handleChange = (event)=> {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
  }

  const checkValidation = () => {
    let errors = validation;

    //first Name validation
    if (!inputValues.fName.trim()) {
      errors.fName = "First name is required";
      errors.subm = "1";
    } else {
      errors.fName = "";
      errors.subm = "";
    }
    //last Name validation
    if (!inputValues.lName.trim()) {
      errors.lName = "Phone number is required";
      errors.subm = "1";
    } else {
      errors.lName = "";
      errors.subm = "";
    }

    // email validation
    const emailCond = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$";
    if (!inputValues.email.trim()) {
      errors.email = "Email is required";
      errors.subm = "1";
    } else if (!inputValues.email.match(emailCond)) {
      errors.email = "Please enter a valid email address";
      errors.subm = "1";
    } else {
      errors.email = "";
      errors.subm = "";
    }

    setValidation(errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validation.subm === ''){
        
        
        const data = {
            billfirstname: inputValues.fName,
            billlastname: inputValues.lName,
            billphone: inputValues.phone,
            billemail: inputValues.email,
            billcountry: inputValues.country,
            billstate: inputValues.state,
            billaddress: inputValues.address,
            billcity: inputValues.city,
            billpincode: inputValues.zip,
            subtotal: subtotal,
            profileid: sessionStorage.getItem("profileid"),
            cartdata:props.carts
        };

        axios.post('http://onkar.world/react_apis/place_order.php', JSON.stringify(data))
  .then(function (response) {
    
    if(response.data.status == "1")
    {
        navigate('/thank-you');
    }
    else{
        setMesg({
                msg:response.data.msg,
                typ:response.data.status === "1" ? "success" : "danger",
            });
    }
  })
  .catch(function (error) {
    setMesg({
        msg:error,
        typ:"danger",
    });
  });

    }

  };

  useEffect(() => {
    cal_subtotal();
    checkValidation();
  }, [inputValues, props.carts]);

  const cal_subtotal = () => {
    let totalamn = 0;
    let totalqtys = 0;
    {
      props.carts.map((items) => (totalamn += items.quantity * items.price));
    }
    {
      props.carts.map((ite) => (totalqtys += ite.quantity));
    }
    setSubtotal(totalamn);
    setSubqty(totalqtys);
  };
  return (
    <>
      <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "100px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">
            Checkout
          </h1>
          <div className="d-inline-flex">
            <p className="m-0">
              <a to="">Home</a>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">Checkout</p>
          </div>
        </div>
      </div>
      <div className="container">
      {mesg !=='' &&
        <div className={`alert alert-${mesg.typ}`} role="alert">{mesg.msg}</div>
      }
        <div className="row">
       
          <div className="col-md-6 order-md-2 mb-4">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-muted">Your cart</span>
              <span className="badge badge-secondary badge-pill">
                {props.carts.length}
              </span>
            </h4>
            <table
              id="cart"
              style={{
                textAlign: "center",
                border: "1px solid #ccc",
                fontSize: "13px",
              }}
            >
              <thead style={{ backgroundColor: "#D19C97" }}>
                <tr>
                  <th className="third">Product</th>
                  <th className="third">Size</th>
                  <th className="third">Color</th>
                  <th className="second">Qty</th>
                  <th className="fourth">Line Total</th>
                </tr>
              </thead>
              <tbody>
                {props.carts.map((item, index) => (
                  <tr key={item.id} className="productitm">
                    <td>{item.name}</td>
                    <td>{item.size}</td>
                    <td>{item.color}</td>
                    <td>
                      <div className="item-controls">
                        <span>{item.quantity}</span>
                      </div>
                    </td>

                    <td>${item.price * item.quantity}</td>
                  </tr>
                ))}

                <tr className="totalprice">
                  <td className="light" colSpan="2">
                    &nbsp;
                  </td>
                  <td>
                    <h6>Total Qty:</h6>
                  </td>
                  <td colSpan="2">
                    <span className="thick">{subqty}</span>
                  </td>
                </tr>

                <tr className="totalprice">
                  <td className="light" colSpan="2">
                    &nbsp;
                  </td>
                  <td>
                    <h6>Total Amount:</h6>
                  </td>
                  <td colSpan="2">
                    <span className="thick">${subtotal}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 order-md-1">
            <h4 className="mb-3">Billing address</h4>
            <form className="needs-validation" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label for="firstName">First name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fName"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                    value={inputValues.fName}
                  />
                  {validation.fName && <p style={{color:"red"}}>{validation.fName}</p>}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="lastName">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lName"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                    value={inputValues.lName}
                  />
                  {validation.lName && <p style={{color:"red"}}>{validation.lName}</p>}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label for="firstName">Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                    value={inputValues.phone}
                  />
                  
                </div>
                <div className="col-md-6 mb-3">
                  <label for="email">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                    value={inputValues.email}
                  />
                  {validation.email && <p style={{color:"red"}}>{validation.email}</p>}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label for="firstName">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                    value={inputValues.address}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label for="lastName">City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                    value={inputValues.city}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-5 mb-3">
                  <label for="country">Country</label>
                  <select
                    className="custom-select d-block w-100"
                    name="country"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.country}
                  >
                    <option value="">Choose...</option>
                    <option value="India">India</option>
                  </select>
                  
                </div>
                <div className="col-md-4 mb-3">
                  <label for="state">State</label>
                  <select
                    className="custom-select d-block w-100"
                    name="state"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.state}
                  >
                    <option value="">Choose...</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Mumbai">Mumbai</option>
                  </select>
                 
                </div>
                <div className="col-md-3 mb-3">
                  <label for="zip">Zip</label>
                  <input
                    type="text"
                    className="form-control"
                    name="zip"
                    placeholder=""
                    onChange={(e) => handleChange(e)}
                    value={inputValues.zip}
                  />
                  
                </div>
              </div>
              <hr className="mb-4" />
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="same-address"
                  checked
                />
                <label className="custom-control-label" for="same-address">
                  Shipping address is the same as my billing address
                </label>
              </div>

              <hr className="mb-4" />
              <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
                onClick={handleChange}
              >
                Order Now
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

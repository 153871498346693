import React, {useState,useEffect} from "react";
//import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from "react-router-dom";
import axios from "axios";


export default function Register() {
    const [mesg,setMesg] = useState('');
    
   
    const [inputValues, setInputValue] = useState({
        fName: "",
        lName: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    
      const [validation, setValidation] = useState({
        fName: "",
        lName: "",
        email: "",
        password: "",
        confirmPassword: "",
        subm:"",
      });
    
      //handle submit updates
      function handleChange(event) {
        const { name, value } = event.target;
        setInputValue({ ...inputValues, [name]: value });
      }
    
      const checkValidation = () => {
        let errors = validation;
    
        //first Name validation
        if (!inputValues.fName.trim()) {
          errors.fName = "First name is required";
          errors.subm="1";
        } else {
          errors.fName = "";
          errors.subm="";
        }
        //last Name validation
        if (!inputValues.lName.trim()) {
          errors.lName = "Phone number is required";
          errors.subm="1";
        } else {
          errors.lName = "";
          errors.subm="";
        }
    
        // email validation
        const emailCond ="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$";
        if (!inputValues.email.trim()) {
          errors.email = "Email is required";
          errors.subm="1";
        } else if (!inputValues.email.match(emailCond)) {
          errors.email = "Please enter a valid email address";
          errors.subm="1";
        } else {
          errors.email = "";
          errors.subm="";
        }
    
        //password validation
        const password = inputValues.password;
        if (!password) {
          errors.password = "password is required";
          errors.subm="1";
        } else if (password.length < 6) {
          errors.password = "Password must be longer than 6 characters";
          errors.subm="1";
        } else {
          errors.password = "";
          errors.subm="";
        }
    
        //matchPassword validation
        const confirmPassword = inputValues.confirmPassword;
        if (confirmPassword === '') {
          errors.confirmPassword = "Password confirmation is required";
          errors.subm="1";
        } else if (confirmPassword !== password) {
          errors.confirmPassword = "Password does not match confirmation password";
          errors.subm="1";
        } else {
          errors.password = "";
          errors.confirmPassword = "";
          errors.subm="";
        }
    
        setValidation(errors);
      };
    
      useEffect(() => {
        checkValidation();        
      }, [inputValues]);
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if(validation.subm === ''){
        
        const data = {
            name: inputValues.fName,
            phone: inputValues.lName,
            email: inputValues.email,
            password: inputValues.password
          };

        axios.post('http://onkar.world/react_apis/register.php', JSON.stringify(data))
  .then(function (response) {

    setMesg({
        msg:response.data.msg,
        typ:response.data.status === "1" ? "success" : "danger",
    });
  })
  .catch(function (error) {    
    setMesg({
        msg:error,
        typ:"danger",
    });
  });
        }

      };

  return (
    <>
      <center style={{ marginTop: "50px" }}>
        <h3>Register</h3>
      </center>
      <div className="container mt-3">
      {mesg !=='' &&
        <div className={`alert alert-${mesg.typ}`} role="alert">{mesg.msg}</div>
      }
      <form
          id="registrationForm"
          action="/"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div className="mb-3">
            <input
              placeholder="Name"
              type="string"
              name="fName"
              id="fName"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.fName}
            />
            {validation.fName && <p style={{color:"red"}}>{validation.fName}</p>}
            {validation.fName && console.log(validation)}
          </div>
          <div className="mb-3">
            <input
              placeholder="Phone"
              type="string"
              id="lName"
              name="lName"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.lName}
            />
            {validation.lName && <p style={{color:"red"}}>{validation.lName}</p>}
          </div>
          <div className="mb-3">
            <input
              placeholder="email"
              type="email"
              name="email"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.email}
            />
          </div>
          {validation.email && <p style={{color:"red"}}>{validation.email}</p>}

          <div className="mb-3">
            <input
              placeholder="password"
              type="password"
              name="password"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.password}
              required
            />
            {validation.password && <p style={{color:"red"}}>{validation.password}</p>}
          </div>
          <div className="mb-3">
            <input
              placeholder="confirm password"
              type="password"
              name="confirmPassword"
              className="form-control"
              onChange={(e) => handleChange(e)}
              value={inputValues.confirmPassword}
              required
            />
            {validation.confirmPassword && <p style={{color:"red"}}>{validation.confirmPassword}</p>}
          </div>
          <span className="form-input-login">
            Already have an account? Login <Link to="/login">here</Link>
          </span><br/>
          <button type="submit" id="submit-button" className="btn btn-primary" onClick={handleChange}>
            Submit
          </button>
          
        </form>
        {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} /> */}
      </div>
    </>
  );
}

import React, {useEffect, useState} from 'react'
import Leftbar from './Leftbar.js'
import { Link } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Tag } from 'primereact/tag';
import { FilterMatchMode } from 'primereact/api';
import "primereact/resources/themes/nova-accent/theme.css";
import axios from "axios";

// run commnand from this url to import primereact https://www.npmjs.com/package/primereact

// https://primereact.org/datatable/

export default function Myorders(props) {
    const [orderdata,setOrderdata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    useEffect(() => {
        productlist();
      }, [props]);

      const productlist = () => {
      axios
        .get(`http://onkar.world/react_apis/my-orders.php?profileid=${sessionStorage.getItem('profileid')}`)
        .then(function (response) {
            setOrderdata(response.data.res);
            setLoading(false);
        })
        .catch(function (error) {});
    };

//     const header = ()=>{
//         return(
//         <div className="flex flex-wrap align-items-center justify-content-between gap-2">
//             <span className="text-xl text-900 font-bold">Products</span>
//         </div>
//     );
// }
    const footer = `In total there are ${orderdata ? orderdata.length : 0} products.`;

    const statusBodyTemplate = (orderdata) => {
        
        return <Tag value={orderdata.status} severity={getSeverity(orderdata)}></Tag>;
    };

    const getSeverity = (orderdata) => {
        
        switch (orderdata.status) {
            case '0':
                return 'Pending';

            case '1':
                return 'Completed';

            case '2':
                return 'Delivered';

            default:
                return null;
        }
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </div>
        );
    };
    const header = renderHeader();


  return (
    <>
    
         <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "100px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">
          My Orders
          </h1>
          
          <div className="d-inline-flex">
            <p className="m-0">
              <Link to="#">Home</Link>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">My Orders</p>
          </div>
        </div>
      </div>
        <div className="container display-table">
        
        <div className="row">
            <Leftbar/>
            <div className="col-md-9 col-sm-11 display-table-cell v-align">
                
                
                <div className="user-dashboard">
                <DataTable dataKey="id" filters={filters} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} 
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}" footer={footer} value={orderdata} filterDisplay="row" loading={loading}
         header={header} tableStyle={{ minWidth: '50rem' }}>
                    <Column sortable field="date" header="Order Date"></Column>
                    <Column sortable field="orderid" header="Order ID"></Column>
                    <Column field="shipping" header="Shipping"></Column>
                    <Column field="total" header="Total Amount"></Column>
                    <Column header="Status" body={statusBodyTemplate}></Column>
                </DataTable>
                </div>
            </div>
        </div>
       
    </div>
    </>
  )
}

import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Routes, Switch, useNavigate } from "react-router-dom";

export default function Leftbar() {
    const navigate = useNavigate();
  useEffect(() => {
    if(sessionStorage.getItem('email')===null)
      {
        navigate('/login');
      } 
  }, []);
  return (
    <>
        <div className="col-md-3 col-sm-1 hidden-xs display-table-cell v-align box" id="navigation">
                <div className="logo">
                   
                </div>
                <div className="navi">
                    <ul>
                        <li><Link to="/dashboard"><i className="fa fa-home" aria-hidden="true"></i><span className="hidden-xs hidden-sm">My Profile</span></Link></li>
                        <li><Link to="/my-orders"><i className="fa fa-tasks" aria-hidden="true"></i><span className="hidden-xs hidden-sm">My Orders</span></Link></li>
                        <li><Link to="/change-pass"><i className="fa fa-calendar" aria-hidden="true"></i><span className="hidden-xs hidden-sm">Change Password</span></Link></li>
                        <li><Link to="/logout"><i className="fa fa-cog" aria-hidden="true"></i><span className="hidden-xs hidden-sm">Logout</span></Link></li>
                    </ul>
                </div>
            </div> 
    </>
  )
}

import React,{useEffect} from 'react'
import { BrowserRouter, Route, Routes, Switch, useNavigate } from "react-router-dom";
import "../Dashboard.css";
import { Link } from "react-router-dom";
import Leftbar from './Leftbar.js'

export default function Dashboard() {
  
  return (
    <>
    <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "100px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">
          Dashboard
          </h1>
          <div className="d-inline-flex">
            <p className="m-0">
              <Link to="#">Home</Link>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">Dashboard</p>
          </div>
        </div>
      </div>
        <div className="container display-table">
        
        <div className="row">
            <Leftbar/>
            <div className="col-md-9 col-sm-11 display-table-cell v-align">
                
                
                <div className="user-dashboard">
                    <h1>Welcome</h1>
                    <div className="row">
                        <div className="col-md-5 col-sm-5 col-xs-12 gutter">
                          <label>Name : {sessionStorage.getItem("name")}</label>
                          <label>Email : {sessionStorage.getItem("email")}</label>
                          <label>Profile ID : {sessionStorage.getItem("profileid")}</label>
                        </div>
                 
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    </>
  )
}

import React, {useState, useEffect} from 'react'
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar'
import Products from "./Products";


export default function Shop(props) {
    const {id, subcategory} = useParams();
    const [progress, setProgress] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [colors,setColors] = useState([]);
    const [sizes,setSizes] = useState([]);

    const handlePrice = (event) => {
        var permissions_array = [...colors];
        if (event.target.checked) {
          permissions_array = [...colors, event.target.value];
        } else {
          permissions_array.splice(colors.indexOf(event.target.value), 1);
        }
        setColors(permissions_array);
      
      };

      const handleSize = (event) => {
        var sizes_array = [...sizes];
        if (event.target.checked) {
            sizes_array = [...sizes, event.target.value];
        } else {
            sizes_array.splice(sizes.indexOf(event.target.value), 1);
        }
        setSizes(sizes_array);
      
      };
console.log(colors);
console.log(sizes);
  return (


    
    <>
  
      <div className="container-fluid bg-secondary mb-5">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: "120px"}}>
            <h1 className="font-weight-semi-bold text-uppercase mb-3">Our Shop</h1>
            <div className="d-inline-flex">
                <p className="m-0"><a href="">Home</a></p>
                <p className="m-0 px-2">-</p>
                <p className="m-0">Shop</p>
            </div>
        </div>
    </div>
    <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    <div className="container-fluid pt-5">
        <div className="row px-xl-5">
            
            <div className="col-lg-3 col-md-12">
                
                {/* <div className="border-bottom mb-4 pb-4">


                    <h5 className="font-weight-semi-bold mb-4">Filter by price</h5>
                    <form>
                       
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="price-1"/>
                            <label className="custom-control-label" htmlFor="price-1">$0 - $100</label>
                            <span className="badge border font-weight-normal">150</span>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="price-2" value="100-200"/>
                            <label className="custom-control-label" htmlFor="price-2">$100 - $200</label>
                            <span className="badge border font-weight-normal">295</span>
                        </div>
                       
                    </form>
                </div>
              */}
                <div className="border-bottom mb-4 pb-4">
                    <h5 className="font-weight-semi-bold mb-4">More Category Products</h5>
                    <form className='row'>
                    
                      
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <Link to="/shop/T+shirt/23">Tshirt's</Link>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <Link to="/shop/All Top Wear/42">All Top Wear</Link>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <Link to="/shop/Tops/45">Tops</Link>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <Link to="/shop/Winter Wear/47">Winter Wear</Link>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <Link to="/shop/Palazzos/49">Palazzos</Link>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <Link to="/shop/Dresses/46">Dresses</Link>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <Link to="/shop/Dresses/50">Shorts</Link>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <Link to="/shop/Dresses/51">Jeggings</Link>
                        </div>
                        
                     
                    </form>
                </div>

                {/* <div className="border-bottom mb-4 pb-4">
                    <h5 className="font-weight-semi-bold mb-4">Filter by color</h5>
                    <form>
                    
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-1" onChange={handlePrice} value="54"/>
                            <label className="custom-control-label" htmlFor="color-1">Antique</label>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-2" onChange={handlePrice} value="55"/>
                            <label className="custom-control-label" htmlFor="color-2">Yellow</label>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-3" onChange={handlePrice} value="56"/>
                            <label className="custom-control-label" htmlFor="color-3">White</label>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-4" onChange={handlePrice} value="57"/>
                            <label className="custom-control-label" htmlFor="color-4">Rose Gold</label>
                        </div>
                        <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="color-5" onChange={handlePrice} value="58"/>
                            <label className="custom-control-label" htmlFor="color-5">Aqua blue</label>
                        </div>
                     
                    </form>
                </div>
               
                <div className="mb-5">
                    <h5 className="font-weight-semi-bold mb-4">Filter by size</h5>
                    <form>
                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-1" onChange={handleSize} value="34"/>
                            <label className="custom-control-label" htmlFor="size-1">S</label>
                    </div>
                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-2" onChange={handleSize} value="35"/>
                            <label className="custom-control-label" htmlFor="size-2">M</label>
                    </div>
                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-3" onChange={handleSize} value="36"/>
                            <label className="custom-control-label" htmlFor="size-3">L</label>
                    </div>
                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-4" onChange={handleSize} value="37"/>
                            <label className="custom-control-label" htmlFor="size-4">XL</label>
                    </div>
                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" className="custom-control-input" id="size-5" onChange={handleSize} value="38"/>
                            <label className="custom-control-label" htmlFor="size-5">XXL</label>
                    </div>
                       
                    </form>
                </div> */}
                
            </div>
           
            <div className="col-lg-9 col-md-12">
                <Products addItem={props.addItem}/>
            </div>
            

            
        </div>
    </div>
    </>
  )
}

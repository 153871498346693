import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

export default function Thankyou(props) {

    useEffect(() => {
        props.removecart();
      }, [props]);

  return (
    <>
        <div className="container-fluid bg-secondary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "100px" }}
        >
          <h1 className="font-weight-semi-bold text-uppercase mb-3">
            Checkout
          </h1>
          <div className="d-inline-flex">
            <p className="m-0">
              <a to="">Home</a>
            </p>
            <p className="m-0 px-2">-</p>
            <p className="m-0">Checkout</p>
          </div>
        </div>
      </div>
      <div><center><h4 style={{color:"green",fontWeight:"bold"}}>Your order has been placed!!! Please click <Link to="/my-orders">Here</Link> to view your orders.</h4></center></div> 
    </>
  )
}
